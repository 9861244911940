import { Routes, Route } from "react-router-dom";
import { lazy } from "react";

const NoMatch = lazy(() => import('../components/root/no-match'));
const Layout = lazy(() => import('../components/root/layout'));
const Home = lazy(() => import("../components/root/home"));

const BackupCloud = lazy(() => import("../components/backup/cloud"));
const BackupDoubleCheck = lazy(() => import("../components/backup/double-check"));
const BackupIUnderstand = lazy(() => import("../components/backup/i-understand"));
const BackupHome = lazy(() => import("../components/backup/home"));
const BackupLayout = lazy(() => import("../components/backup/layout"));
const BackupRecoveryPhrase = lazy(() => import("../components/backup/recovery-phrase"));
const BackupWordOrder = lazy(() => import("../components/backup/word-order"));

const CreateFund = lazy(() => import("../components/create/fund"));
const CreateInitialDeposit = lazy(() => import("../components/create/initial-deposit"));
const CreateHome = lazy(() => import("../components/create/home"));
const CreateLayout = lazy(() => import("../components/create/layout"));
const CreateResponsibilities = lazy(() => import("../components/create/responsibilities"));
const CreateSecurity = lazy(() => import("../components/create/security"));

const RestoreForgotRecoveryPhrase = lazy(() => import("../components/restore/forgot-recovery-phrase"));
const RestoreHome = lazy(() => import("../components/restore/home"));
const RestoreLayout = lazy(() => import("../components/restore/layout"));

const WalletHome = lazy(() => import("../components/wallet/home"));
const WalletLayout = lazy(() => import("../components/wallet/layout"));

const SettingsHome = lazy(() => import("../components/settings/home"));
const SettingsLayout = lazy(() => import("../components/settings/layout"));

const SettingsGeneralHome = lazy(() => import("../components/settings/general/home"));
const SettingsGeneralBitcoinUnit = lazy(() => import("../components/settings/general/bitcoin-unit"));
const SettingsGeneralLanguage = lazy(() => import("../components/settings/general/language"));
const SettingsGeneralLocalCurrency = lazy(() => import("../components/settings/general/local-currency"));
const SettingsGeneralLayout = lazy(() => import("../components/settings/general/layout"));


export default function AppRoutes() {
    return (
        <>
            {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. */}
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />

                    <Route path="/backup" element={<BackupLayout />}>
                        <Route index element={<BackupHome />} />
                        <Route path="cloud" element={<BackupCloud />} />
                        <Route path="double-check" element={<BackupDoubleCheck />} />
                        <Route path="i-understand" element={<BackupIUnderstand />} />
                        <Route path="recovery-phrase" element={<BackupRecoveryPhrase />} />
                        <Route path="word-order" element={<BackupWordOrder />} />
                    </Route>

                    <Route path="/create" element={<CreateLayout />}>
                        <Route index element={<CreateHome />} />
                        <Route path="fund" element={<CreateFund />} />
                        <Route path="initial-deposit" element={<CreateInitialDeposit />} />
                        <Route path="responsibilities" element={<CreateResponsibilities />} />
                        <Route path="security" element={<CreateSecurity />} />
                    </Route>

                    <Route path="/restore" element={<RestoreLayout />}>
                        <Route index element={<RestoreHome />} />
                        <Route path="forgot-recovery-phrase" element={<RestoreForgotRecoveryPhrase />} />
                    </Route>

                    <Route path="/wallet" element={<WalletLayout />}>
                        <Route index element={<WalletHome />} />
                    </Route>

                    <Route path="/settings" element={<SettingsLayout />}>
                        <Route index element={<SettingsHome />} />
                        <Route path="general" element={<SettingsGeneralLayout />}>
                            <Route index element={<SettingsGeneralHome />} />
                            <Route path="bitcoin-unit" element={<SettingsGeneralBitcoinUnit />} />
                            <Route path="local-currency" element={<SettingsGeneralLocalCurrency />} />
                            <Route path="language" element={<SettingsGeneralLanguage />} />
                        </Route>
                    </Route>

                    {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </>
    );
}
