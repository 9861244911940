import { Container, Typography } from "@mui/material";

export default function Loading() {
    return (
        <Container maxWidth="sm">
            <Typography component="p" variant="caption" align="center">
                Loading...
            </Typography>
        </Container>
    );
};
